import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function GeneralSection() {
  const classes = useStyles();
  return (
    <div className={classes.sections}>
			<div className={classes.container}>
				<div className={classes.title}>
					<h2>Die Anfänge</h2>	
				</div>
				<div className={classes.title}>
					<h4>
					Die Geschichte des Radfahrvereins begann am 15. Mai 1904, als im heutigen Gasthaus Löwen acht Männer den RV 04 gründeten. Das Vereinsleben bestand in erster Linie aus dem Besuch von Heimatfesten, gemeinsamen Ausflügen sowie Geselligkeit und Kameradschaft. Neun Jahre später wurde der RV Einigkeit von 17 Männern ins Leben gerufen, die sich auf Korsofahren, Radrennen und Langsamfahren spezialisierten. Während der Weltkriege ruhte das Vereinsleben beider Radsportvereine. Nach dem 2. Weltkrieg musste völlig neu begonnen werden, denn die Geräte und das Vereinsvermögen gingen verloren. Der RV 04 legte mit dem Kauf einer Saalmaschine den Grundstein für das Kunstradfahren. Die Zehnergruppe, die im Tanzsaal des Gasthauses Adler trainierte, war von ihrem ersten Auftritt beim 45-jährigen Jubiläum an ein Aushängeschild des Vereins. Am 23. September 1951 gab es die erste gemeinsame Sportveranstaltung beider Vereine. Hier wurde auch das erste Radballspiel in Mömlingen gezeigt.
					</h4>
				</div>
				<div className={classes.title}>
					<h2>Die wilden Siebziger</h2>	
				</div>
				<div className={classes.title}>
					<h4>
					Am 10. Dezember 1972 fusionierten die beiden Vereine zur RV Einigkeit 04 Mömlingen. Die sportlichen Erfolge blieben nicht aus. Nach dem Bau der Kultur- und Sporthalle 1975 konnte sich der Verein bei nunmehr sehr guten Trainingsbedingungen nicht nur im Kunstradfahren, sondern auch in der neu gebildeten Radballabteilung sehr gut entwickeln. Bei den bayrischen Hallen-Radsportmeisterschaften, die 1976 erstmals in Mömlingen ausgetragen wurden, erreichte das Zweierpaar Ulrike Babilon/Ilona Helm den Vizetitel. 1978 holte man sich in Augsburg mit Babilon/Helm und Helma Lieb gleich zwei bayrische Meistertitel nach Mömlingen. Babilon und Helm wurden in den bayrischen Kader aufgenommen und verloren bis zu ihrem Rücktritt 1981 keinen Wettkampf in Bayern.
					</h4>
				</div>
				<div className={classes.title}>
					<h2>Die 80er</h2>	
				</div>
				<div className={classes.title}>
					<h4>
					Nach 1981 traten Heike Helm/Simone Lieb und Heike Helm/Michaela Jöbges ihre Siegeszüge an und konnten ebenfalls den Bayernmeister Titel mit nach Mömlingen bringen. Auch der Radball kam wieder in Schwung. Klaus-Peter Lieb und Uwe Ritter sowie Bernd Bickert und Volker Roscher wurden Bezirksmeister. 1985 veranstaltete man unter der Regie  von Julius Helm erstmals ein grosses internationales Radballturnier, an dem die 20fachen (!) Weltmeister Jan und Jindrich Pospisil, die Vizeweltmeister Gebrüder Steinmeier sowie die Weltmeisterdritten aus der Schweiz teilnahmen. Von nun an wurden viele landes- und bundesweite Meisterschaften in Mömlingen ausgetragen. 
					</h4>
				</div>
				<div className={classes.title}>
					<h2>Die erfolgreichen 90er</h2>	
				</div>
				<div className={classes.title}>
					<h4>
					1991 starteten die Radball-Schüler Torsten Herrmann und Marco Schuck ihren sportlichen Triumphzug. Bis heute erreichten sie viermal den Titel des Bezirksmeisters und viermal den Titel des bayrischen Meisters. Dreimal wurden die beiden Süddeutscher Meister, je einmal Vize-Süddeutscher und Vize-Deutscher Meister. 1992 erreichten sie ihren Höhepunkt mit dem Gewinn der Deutschen B-Schüler-Meisterschaft und sind somit bis jetzt die erfolgreichsten Sportler in der Geschichte des RV Einigkeit 04. 
					</h4>
				</div>
			</div>
		</div>
	);
}
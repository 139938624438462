import React from "react";
//import axios from 'axios';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import LocationOnIcon from '@material-ui/icons/LocationOn';
import GoogleMapReact from 'google-map-react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";


import styles2 from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

/*const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={LocationOnIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)*/

export default function GeneralSection() {
    const classes = useStyles();
	const classes2= useStyles2();
    const init = {name:"",email:"",message:""};
	const [nameError,setNameError] = React.useState(false);
	const [emailError,setEmailError] = React.useState(false);
	const [msgError,setMsgError] = React.useState(false);
	const [nameText,setNameText] = React.useState("Dein Name:");
	const [emailText,setEmailText] = React.useState("Deine Email:");
	const [msgText,setMsgText] = React.useState("Deine Nachricht:");
    //const [input,setInput] = React.useState({});
	//configs for google forms. 
	/*const gformconfig={
		cors: 'https://cors-anywhere.herokuapp.com/', // <optional> doesn't display the cors error
		formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdu61PGt6ElOSr5ozUZmyUvODfnUHqdlv5_i0rYbwE3QI765w/formResponse'
	};*/
	//check input before submitting, for consistency
	const validateForm = ()=>{
		const {inputs} = state;
		let validateForm=true;
		if (inputs.name.value.length===0){
			validateForm=false;
			setNameText("Gib Bitte deinen Namen ein!");
			setNameError(true);
		}
		if (inputs.email.value.length===0){
			validateForm=false;
			setEmailText("Gib Bitte deine Emailaddresse ein!");
			setEmailError(true);
		}
		if (inputs.message.value.length===0){
			validateForm=false;
			setMsgText("Gib Bitte deine Nachricht ein!");
			setMsgError(true);
		}
		return validateForm;
	};
	//data for google maps
	const defaultProps = {
       center: {lat: 49.850496, lng: 9.086534}, 
       zoom: 13
	};
	//submit the input date to google forms
	const doSubmit = function(e) {
		e.preventDefault();
		const {inputs} = state;
		console.log('dosubmit')
		
		if (validateForm()){
			console.log('dosubmitTre')
			const formData = new FormData();
			formData.append('Name',inputs.name.value)
			formData.append('Email',inputs.email.value)
			formData.append('Nachricht',inputs.message.value)

			
            var xhr = new XMLHttpRequest();
            xhr.open("POST", "https://form.taxi/s/cxcmwjnr", true);
            xhr.setRequestHeader('Accept','application/json');
            
            
            
            xhr.onload = function(e) {
                // response
                try {
                    var response = JSON.parse(xhr.response);
                } catch(e) {
                    var response = {success: false, error_msg: "no json request"}
                }

                // success
                if (xhr.status === 200 && response.success === true) {

                    //display success message
                    alert("Deine Nachricht wurde erfolgreich gesendet! \n\nWir werden uns in Kürze bei dir melden.");
                }
                // error
                else {

                    //display error message
                    var msg = response.error;
                    if (response.error_msg !== '')
                        msg = response.error_msg;
                    alert("Ihre Nachricht konnte nicht gesendet werden.\nError:"+msg);
                }
            };
            
            xhr.send(formData);
            
		}
	};
	//save input date in state
    const handleChange = (e)=>{
		const {value,id}=e.target;
		state.inputs[id].value=value;
	}
	//state for saving inputs
	const state={
		inputs:{
			name:{ id: 482338292, value:''},
			email: {id: 1736559063, value:''},
			message: {id: 482590513, value:''}
		}
	}
	//GoogleMapsMarker 
	const renderMarkers = (map, maps) => {
		let marker = new maps.Marker({
		position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
		map,
		title: 'Kultur- und Sporthalle'
		});
		return marker;
	 };
	
  return (
    <div className={classes.sections}>
			<div className={classes.container}>
				<div className={classes.title}>
					<h2>Vorstand</h2>	
				</div>
				
				<div>
					<GridContainer>
						<GridItem xs={12} sm={12} md={4}>
							<InfoArea 
								title="1. Vorstand"
								description="Florian Lieb"
								icon={RecentActorsIcon}
								iconColor="info"
								vertical
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={4}>
							<InfoArea 
								title="2. Vorstand"
								description="Sascha Graner"
								icon={RecentActorsIcon}
								iconColor="info"
								vertical
							/>
						</GridItem>
					</GridContainer>
				</div>
				
				<div className={classes.space20}></div>
				<div className={classes.title}>
					<h2>Anfahrt Halle</h2>
				</div>
				<GridContainer>
					<GridItem xs={12} sm={12} md={4}>
						<div className={classes.title}>
							<h4>Ludwig-Ritter-Halle Mömlingen<br/>Königsberger Straße 2<br/>63853 Mömlingen</h4>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={8}>
						<div style={{ height: '40vh', width: '100%' , marginTop:'30px'}}>
							<GoogleMapReact
								 bootstrapURLKeys={{ key: "AIzaSyDA01bAQ3XgcPOKWIQbr8ypSX5uGl4pAZs" }}
								 defaultCenter={defaultProps.center}
								 center={defaultProps.center}
								 defaultZoom={defaultProps.zoom}
								 yesIWantToUseGoogleMapApiInternals
      					 onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
							>
								
							</GoogleMapReact>
						</div>
					</GridItem>
				</GridContainer>
				
				<div className={classes.space50}></div>
				<div className={classes.title}>
					<h2>Kontaktformular</h2>
				</div>
				<form name="gform" id="gform" onSubmit={doSubmit} onChange={handleChange}>
				    <GridContainer>
						<GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={nameText}
                                id="name"
                                name="name"
                                error= {nameError}
                                onChange={handleChange}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={emailText}
                                type="email"
                                id="email"
                                name="email"
                                error= {emailError}
                                onChange={handleChange}
                                formControlProps={{
                                    fullWidth: true
                                }}
                               
                            />
                        </GridItem>
                        <CustomInput
                            labelText={msgText}
                            type="text"
                            id="message"
                            name="message"
                            error= {msgError}
                            onChange={handleChange}
                            formControlProps={{
                                fullWidth: true,
                                className: classes2.textArea
                            }}
                            inputProps={{
                                multiline: true,
                                rows: 5
                            }}
                            
                        />
                        <GridItem xs={12} sm={12} md={4}>
                            <Button color="info" type="submit">Nachricht senden</Button>
                        </GridItem>
					</GridContainer>
				</form>
				
				<div className={classes.space20}></div>
				<div className={classes.title}>
					<h2>Impressum</h2>
				</div>
				<div className={classes.title}>
					<h4>Radfahrverein "Einigkeit" 04 Mömlingen e.V.<br/>vertreten durch: <br/> Dr. Florian Lieb <br/>Schillerstraße 7<br/>63853 Mömlingen <br/> <br/>Registergericht und Registernummer: <br/>Amtsgericht Aschaffenburg, VR 20020</h4>
				</div>
			</div>
		</div>
	);
}
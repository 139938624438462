import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderLinks from "components/Header/HeaderLinks.js";


//section
import VereinSection from "./Sections/VereinSection.js"

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);


export default function VereinPage(props) {
	const classes = useStyles();
	const{...rest} = props;
	return (
		<div>
			<Header
        color="transparent"
        routes=""
        brand="RV Mömlingen"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white"
        }}
        {...rest}
      />
		
			<Parallax small filter image={require("assets/img/bg10.jpg")}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={6}>
							<h1 className={classes.title}>Vereinsgeschichte.</h1>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			
			<div className={classNames(classes.main, classes.mainRaised)}>
				<VereinSection />
			</div>

			<Footer />
		</div>
	);
}
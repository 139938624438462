import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import Carousel from "react-slick";

import image1 from "assets/img/DSC01414.jpg";
import image2 from "assets/img/DSC01427.jpg";
import image3 from "assets/img/DSC01436.jpg";
import image4 from "assets/img/DSC01442.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import stylesC from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);
const useStylesC = makeStyles(stylesC);


export default function GeneralSection() {
  const classes = useStyles();
	const classesC = useStylesC();
	const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className={classes.sections}>
			<div className={classes.container}>
				<div className={classes.title}>
					<h2>Allgemein</h2>	
				</div>
				<div className={classes.title}>
					<h4> Radball ist eine Hallensportart, bei der mit speziellen Fahrrädern in Mannschaften zu je zwei Spielern auf Tore gespielt wird. Gespielt wird dabei 2 x 7 Minuten. Das klingt erstmal nicht sonderlich viel... Die Regeln sind dabei ähnlich wie beim Fussball mit einigen Ausnahmen. <br/> Berührt ein Spieler während des Spiels den Boden, so ist er nicht mehr spielberechtigt. Um wieder aktiv ins Spielgeschehen eingreifen zu können, muss er erst die verlängerte Torlinie seines eigenen Tores überfahren. Missachtet er dies, gibt es Freistoß, im eigenen Strafraum ein 4-m-Strafstoß. <br/> Weiterhin darf auch immer nur ein Spieler einer Mannschaft sich im eigenen Strafraum befinden. Dieser Spieler darf den Ball auch mit den Händen abwehren, sofern beide Füße auf den Pedalen sind. <br/> Auch wenn es eine nicht sehr bekannte Sportart ist, ist sie in Deutschland doch recht beliebt. Es existieren viele Vereine die aktiv Radball spielen, auch bei uns in der näheren Umgebung. Niedernberg, Goldbach oder Stockstadt um nur einige zu nennen.  
					</h4>	
				</div>
				<div className={classes.space20}></div>
					<div className={classes.title}>
						<h2>Bilder</h2>	
					</div>
					<div className={classes.title}>
						<h4>Hier findet ihr einige Impressionen von der Radball WM 2012 in der Aschaffenburger FanFrankenstolz Arena:</h4>	
					</div>
					<GridContainer>
						<GridItem xs={12} sm={12} md={8} className={classesC.marginAuto}>
							<Card carousel>
								<Carousel {...settings}>
									<img src={image1} alt="First slide" className="slick-image" />
									<img src={image2} alt="Second slide" className="slick-image" />
									<img src={image3} alt="Third slide" className="slick-image" />
									<img src={image4} alt="Fourth slide" className="slick-image" />
								</Carousel>
							</Card>
						</GridItem>
					</GridContainer>
				<div className={classes.space20}></div>
				<div className={classes.title}>
					<h2>Aktive</h2>	
				</div>
				<div className={classes.title}>
					<h4> Schon seit einigen Jahren ist Mömlingen mit einer aktiven Mannschaft in der Bezirksliga Unterfranken West bei der Radball Elite vertreten. Die Mannschaft um Sascha Graner und Florian Lieb ist jedes Jahr wieder ein heißer Kandidat für die mittleren Tabellenplätze. Solange sie dabei aber wenigstens die Niedernberger Mannschaften in der Tabelle hinter sich lassen, ist die Saison schon gut verlaufen. Ambitioniertere Ziele wie der Aufstieg in die Landesliga werden sicherlich in naher Zukunft auch ein Thema sein. <br/>  Die aktuellen Ergebnisse dieser Saison und die aktuelle Tabelle finden sich in der Rubrik Ergebnisse. 
					</h4>	
				</div>
				<div className={classes.space20}></div>
				<div className={classes.title}>
					<h2>Jugend</h2>	
				</div>
				<div className={classes.title}>
					<h4>
						Seit einigen Jahren ist auch wieder in der Mömlinger Radball Jugend Schwung reingekommen. Zur Zeit trainieren wir 14 Kinder im Alter von 5 bis 15 Jahren. Die speziellen Fahrräder sind für unsere Jugend natürlich auch in kleineren Größen verfügbar.
					</h4>	
				</div>
				<div className={classes.space20}></div>
				<div className={classes.title}>
					<h2>Trainingszeiten</h2>	
				</div>
				<div className={classes.title}>
					<h4> Wir freuen uns auf jeden, der gerne einmal die Sportart Radball ausprobieren möchte. Egal ob jung oder alt. Der Jüngste ist bei uns 5 Jahre alt, der älteste Mitte 50. Ihr könnt einfach bei uns vorbeischauen oder mit uns Kontakt aufnehmen. Unsere Trainingszeiten sind: <br/> Immer Montags in der Ludwig-Ritter-Halle <br/> 17:15 - 18:00 Kinder <br/> 18:00 - 19:00 Jugend <br/> 19:00 - 21:00 Aktive
					</h4>	
				</div>
				
			</div>
		</div>
	);
}

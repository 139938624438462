import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
//import LandingPage from "views/LandingPage/LandingPage.js";
//import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import RadballPage from "views/RadballPage/RadballPage.js";
import VereinPage from "views/VereinPage/VereinPage.js"
import KontaktPage from "views/KontaktPage/KontaktPage.js"

var hist = createBrowserHistory();

hist.listen(_ => {
    window.scrollTo(0, 0)  
})


ReactDOM.render(
  <Router history={hist} >
    <Switch>
      <Route path="/Verein" component={VereinPage} />
			<Route path="/Radball" component={RadballPage} />
      <Route path="/Kontakt" component={KontaktPage} />
      <Route path="/Ergebnisse" component={LoginPage} />
      <Route path="/" component={Components} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

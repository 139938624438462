import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Warning from "@material-ui/icons/Warning";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import SnackbarContent from "components/Snackbar/SnackbarContent.js";
//import Clearfix from "components/Clearfix/Clearfix.js";


import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
//import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Herzlich Willkommen</h2>
        </div>
        <div className={classes.title}>
            <h4>Hier findet ihr alles über unseren Verein. Über Radball, unsere aktiven Mannschaften, Jugendarbeit und Trainingszeiten. Aber auch aktuelle Termine, Infos zum Grenzfest oder den Ferienspielen.</h4>  
        </div>
        <div className={classes.title}>
            <h3>Aktuelles:</h3>
            <ul>
            <li>Am 4.12.2022 fand in Hof der U15 Bayernliga Nord Spieltag statt. Bastian Graner und Hendrik Stühlinger belegten am Ende den 2. Platz und erspielten sich somit die Teilnahme an der Qualifikation zur Bayerischen Meisterschaft.</li>
            <li>Am 5.02.2023 findet in Niedernberg der 1. Spieltag für unsere Radball Mannschaften statt. Unsere Elite sowie unsere 3 Jugendmannschaften (U17, U15 und U13) werden ab 10:00 in der Hans-Hermann Halle an den Start gehen.</li>
            <li>Am 12.02.2023 findet in Stein die Qualifikation zur Bayerischen Meisterschaft der U15 statt.  </li>
            </ul>
			
            
      </div>
        </div>
        
        <div className={classes.space50} />
        
    </div>
  );
}

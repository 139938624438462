import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Carousel from "react-slick";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// Sections
import GeneralSection from "./Sections/GeneralSection.js"
//import CarouselSection from "./Sections/CarouselSection.js"
//import AktiveSection from "./Sections/AktiveSection.js"
//import TrainingSection from "./Sections/TrainingSection.js"

//import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";


const useStyles = makeStyles(styles);


export default function RadballPage(props) {
	const classes = useStyles(); 
	
	
	const { ...rest } = props;
	return(
		<div>
			<Header
        color="transparent"
        routes=""
        brand="RV Mömlingen"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white"
        }}
        {...rest}
      />
		
			<Parallax small filter image={require("assets/img/bg10.jpg")}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={6}>
							<h1 className={classes.title}>Radball.</h1>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			
			<div className={classNames(classes.main, classes.mainRaised)}>
				<GeneralSection />
			</div>
		
			<Footer />
		</div>
	);
}